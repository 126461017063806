import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { AwsCognitoService } from './aws-cognito-oauth.service';
import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { ApiEnrolamientoService } from './api/enrolamiento.service';

@Injectable({
  providedIn: 'root',
})
export class TokenResolverService implements Resolve<any> {
  usuario: any;
  constructor(
    private awsCognitoService: AwsCognitoService,
    private router: Router,
    private user: UserService,
    private api: ApiEnrolamientoService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    const urlParams: URLSearchParams = new URLSearchParams(
      window.location.search
    );
    const code: string = urlParams.get('code');

    if (!code) {
      console.error('Cógnito code is null');
      return null;
    }
    this.getTokenDetailsFromCognito(code);
  }

  getTokenDetailsFromCognito(code: string) {
    this.user.logout();
    return this.awsCognitoService
      .getTokenDetailsFromCognito(code)
      .then(r => {
        if (r) {
          this.user.setToken(r);
          this.user.start();
          this.setUser();
          this.router.navigate(['/']);
          return true;
        }
      })
      .catch(e => {
        console.error(e);
        return false;
      });
  }
  setUser() {
    this.api
      .getUsuarioActual()
      .then(result => {
        const r: any = result;
        this.usuario = r.data[0];
        this.api
          .getEsDocenteFamiliarOAdmin(this.usuario.id)
          .then(resultDFoA => {
            const DFoA: any = resultDFoA;

            if (
              DFoA.docente == true &&
              DFoA.familiar == false &&
              DFoA.admin == false
            ) {
              console.log(this.usuario.id);
              this.api
                .registrarLoginDocente(this.usuario.id)
                .then(resultDFoA => {
                  console.log(resultDFoA);
                })
                .catch(error => {
                  console.error(error);
                });
            }
          })
          .catch(error => {
            console.error(error);
          });
      })
      .catch(error => {
        console.error(error);
        //      this.logout();
        //      this.router.navigate(['home']);
      });
  }
}
